@import "~antd/dist/antd.css";

.logo {
  width: 120px;
  height: 40px;
  margin: 10px 24px 10px 0;
  float: left;
}

@media screen and (max-width: 600px) {
  .navbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .paging {
    text-align: center;
  }
}
